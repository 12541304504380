<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">用户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业客户</span>
      </div>

      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字：</span>
          <el-input
            placeholder="请输入企业名称、企业统一信用编码"
            v-model="formInline.keywords"
            @input="query"
            size="mini"
            style="margin-right: 10px; width: 300px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
          <span class="seach_text">认证状态：</span>

          <el-select
            size="mini"
            v-model="authenticateState"
            placeholder="请选择"
            @change="query"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, i) in authenList"
              :key="i"
            ></el-option>
          </el-select>
        </div>
        <el-button
          v-access
          data-ctrl="enterpriseAdd"
          @click="addObject"
          size="small"
          style="margin:0 10px"
          type="primary"
          icon="el-icon-plus"
        >新建</el-button>
      </div>
    </div>
    <!-- <ListFilter :total="total" @close="putAway"> </ListFilter> -->
    <!-- <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      style="text-align: left"
    >
      <el-form-item>
        <el-input
          style="width: 1100px"
          placeholder="请输入用户企业名称、企业统一信用编码"
          v-model="formInline.keywords"
          @input="loadData()"
        >
          <template slot="prepend">企业检索</template>
        </el-input> 
      </el-form-item>
    </el-form>-->
    <el-table :data="list" style="width: 100%">
      <el-table-column label="企业名称 & 统一信用编码" width="280" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="clickColor" @click="goDetail({ code: scope.row.code })">{{ scope.row.name }}</div>
          <div>{{ scope.row.idCardNumber }}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column
        width="120"
        label="营业执照照片"
        prop="idCardImage"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-popover placement="right-start" title="图片" trigger="hover">
            <img
              :src="scope.row.idCardImage"
              alt=""
              srcset=""
              style="width: 300px"
            />
            <span
              slot="reference"
              trigger="hover"
              class="el-icon-postcard font-color-brand"
              style="cursor: pointer"
              >查看</span
            >
          </el-popover>
        </template>
      </el-table-column>-->

      <el-table-column label="法人姓名 & 法人身份证号" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.juridicalPersonName }}</div>
          <div class="clickColor">{{ scope.row.juridicalPersonId }}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column
        width="120"
        label="法人身份证正面"
        prop="juridicalPersonIdFont"
      >
        <template slot-scope="scope">
          <el-popover placement="right-start" title="图片" trigger="hover">
            <img
              :src="scope.row.juridicalPersonIdFont"
              alt=""
              srcset=""
              style="width: 300px"
            />
            <span
              slot="reference"
              trigger="hover"
              class="el-icon-postcard font-color-brand"
              style="cursor: pointer"
              >查看</span
            >
          </el-popover>
        </template>
      </el-table-column>-->

      <!-- <el-table-column
        width="120"
        label="法人身份证反面"
        prop="juridicalPersonIdBack"
      >
        <template slot-scope="scope">
          <el-popover placement="right-start" title="图片" trigger="hover">
            <img
              :src="scope.row.juridicalPersonIdBack"
              alt=""
              srcset=""
              style="width: 300px"
            />
            <span
              slot="reference"
              trigger="hover"
              class="el-icon-postcard font-color-brand"
              style="cursor: pointer"
              >查看</span
            >
          </el-popover>
        </template>
      </el-table-column>-->

      <!-- <el-table-column label="银行" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.bankName }}
        </template>
      </el-table-column>

      <el-table-column label="银行卡号" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.bankCode }}
        </template>
      </el-table-column>

      <el-table-column label="开户地" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.bankArea }}
        </template>
      </el-table-column>

      <el-table-column label="支行" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.bankBranch }}
        </template>
      </el-table-column>

      <el-table-column label="支行联行号" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.bankBranchCode }}
        </template>
      </el-table-column>

      <el-table-column label="短信计数" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.smsLimit }}
        </template>
      </el-table-column>

      <el-table-column label="合同计数" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.contractLimit }}
        </template>
      </el-table-column>

      <el-table-column label="创客认证计数" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.makerVertify }}
        </template>
      </el-table-column>-->

      <el-table-column label="认证状态" width="400">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.authenticateState === '0'"
          >{{ scope.row.authenticateState | enterpriseCertiState }}</el-tag>
          <el-tag
            type="info"
            v-if="scope.row.authenticateState === '2'"
          >{{ scope.row.authenticateState | enterpriseCertiState }}</el-tag>
          <el-tag
            type="warning"
            v-if="scope.row.authenticateState === '1'"
          >{{ scope.row.authenticateState | enterpriseCertiState }}</el-tag>
          <el-tag
            type="danger"
            v-if="scope.row.authenticateState === '4'"
          >{{ scope.row.authenticateState | enterpriseCertiState }}</el-tag>
          <el-tag
            type="success"
            v-if="scope.row.authenticateState === '3'"
          >{{ scope.row.authenticateState | enterpriseCertiState }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: #c2c2c2">
            {{
              scope.row.createTime | dateVal
            }}
          </span>
        </template>
      </el-table-column>
      <!-- 
      <el-table-column label="结算中心" show-overflow-tooltip>
        <template slot-scope="scope">
          <div :class="classVal">{{ scope.row.mybankAccount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="费率" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.mybankBalance }}
        </template>
      </el-table-column>-->
      <el-table-column label="操作" show-overflow-tooltip>
        <template slot-scope="scope">
          <div
            class="shuxian"
            v-show="scope.row.authenticateState !== '3'"
            v-access
            data-ctrl="enterpriseDel"
          >
            <el-popover
              placement="top"
              width="400"
              trigger="manual"
              :value="visible"
              :ref="'popover-' + scope.row.id"
            >
              <div class="del-pop">
                <img src="../../assets/del.png" alt />
              </div>
              <div class="del-title">删除企业信息</div>
              <div class="del-sumtitle">确认删除该条企业资料信息？</div>
              <div class="del-btn">
                <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
              </div>
              <el-button slot="reference" type="text" @click="pOpen(scope.row.id)">删除</el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>
          <div
            class="shuxian"
            v-show="scope.row.authenticateState !== '3'"
            v-access
            data-ctrl="enterprise.listEdit"
          >
            <span class="clickColor" @click="goEdit(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
          </div>
          <div
            class="shuxian"
            v-access
            data-ctrl="tradeCreateAccount"
            v-show="scope.row.authenticateState === '3'"
          >
            <el-button type="text" @click="createAccount(scope.row.code)">开通结算中心</el-button>

            <el-divider direction="vertical"></el-divider>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <addDialog :dialogVisible.sync="addDialogVisible" @func="addClose()"></addDialog>
    <editDialog
      :dialogVisible.sync="editDialogVisible"
      :dialogData="dialogData"
      @close="editClose()"
    ></editDialog>

    <!-- 开通结算中心 -->
    <AddMybankAccount :code="code" :dialogVisible.sync="bankVisible"></AddMybankAccount>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const request = api('/enterprise');
export const createAccounts = api('/mybank')('account.create.json');
// export const enterpriseList = api()('enterprise.simple.list.json');
export const enterpriseEdit = api()('enterprise.edit.json');
// export const userAddCustomerToSubuser = api()(
//   "user.add.customer.to.subuser.json"
// );
export default {
  components: {
    addDialog: () => import('./enterprise_info/Add'),
    editDialog: () => import('./enterprise_info/Edit'),
    // ListFilter: () => import("/src/components/filter"),
    AddMybankAccount: () => import('../user/addMybankAccount.vue'),
  },
  data () {
    return {
      enterpriseList: [],
      agentDialogVisible: false,
      visible: false,

      ruleForm: {
        agentUser: '',
      },
      rules: {
        agentUser: [
          { required: true, message: '请选择代理人', trigger: 'change' },
        ],
      },
      enterpriseCode: '',
      filterOpen: false,
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      payPlatforms: [],
      formInline: {
        keywords: '',
      },
      list: [],
      addDialogVisible: false,
      editDialogVisible: false,
      dialogData: null,
      authenticateState: '',
      // "0"：未认证 "1"：打款中  "2"：打款成功  "3"：认证成功 "4"认证失败
      authenList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '未认证',
          value: '0',
        },
        {
          label: '已认证',
          value: '3',
        },
      ],
      classObject: {
        'font-color-danger': true,
      },
      code: '',
      bankVisible: false,
    };
  },
  computed: {
    classVal: function () {
      return {
        'font-color-warning': true,
      };
    },
  },

  created () {
    console.log(this.$route.query.authenticateState, '1111');
    this.authenticateState = this.$route.query.authenticateState;
    // if (this.$route.query.authenticateState) {
    //
    // }
    this.loadData();
    this.payPlatforms = this.$route.params.$preload.platformList;

    // this.getenterpriseList();
  },
  methods: {
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      this.pClose(id);
    },
    pClose (id) {
      this.$refs['popover-' + id].doClose();
    },
    keywords () {
      this.pageNumber = 1;
      this.loadData();
    },
    createAccount (code) {
      this.code = code;
      this.bankVisible = true;
    },
    // async getenterpriseList () {
    //   const res = await enterpriseList({
    //     pageNumber: 1,
    //     pageSize: 10000,
    //   });
    //   this.enterpriseList = res.list;
    // },
    async createPlatform (obj) {
      await createAccounts({
        pay_platform: obj.platform,
        code: obj.code,
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      console.log(val);

      this.pageNumber = val;

      this.loadData();
    },
    loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        authenticateState: this.authenticateState,
        $ignoreRepeat: true,
      };
      request('enterprise.list.json')(params).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    onSubmit () {
      console.log('submit!');
    },
    addObject () {
      this.addDialogVisible = true;
    },
    goEdit (obj) {
      this.dialogData = obj.code;
      this.editDialogVisible = true;
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/enterprise/detail',
        query: {
          ...obj,
          type: 'platform',
        },
      });
    },
    del (obj) {
      request('enterprise.del.json')({ code: obj.code }).then(() => {
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });
    },
    addClose () {
      this.loadData();
    },
    editClose () {
      // this.editDialogVisible = false;
      this.loadData();
    },
    query () {
      this.pageNumber = 1;
      this.loadData();
    },
    putAway () {
      this.formInline.keywords = '';
      this.authenticateState = null;
      this.pageNumber = 1;
      this.loadData();
    },
    addAgent (row) {
      this.enterpriseCode = row.code;
      this.ruleForm.agentUser = row.agentUser;
      this.agentDialogVisible = true;
    },
    submitForm (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // await userAddCustomerToSubuser({
          //   enterpriseCode: this.enterpriseCode || "",
          //   agentUserCode: this.ruleForm.agentUser || ""
          // });
          await enterpriseEdit({
            code: this.enterpriseCode,
            agentEnterprise: this.ruleForm.agentUser,
            agentEnterpriseName: this.enterpriseList.find(
              (it) => it.code === this.ruleForm.agentUser,
            ).name,
          });
          this.$message.success('分配成功！');
          this.closeDialog(form);
          this.loadData();
        } else {
          return false;
        }
      });
    },
    closeDialog (form) {
      this.$refs[form].resetFields();
      this.agentDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  .header_top {
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }
    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }
    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}
.table_border {
  margin: 20px 0;
  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }
  .table_demo {
    padding: 20px 0;
    font-size: 10px;
  }
}
.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}
.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
</style>
